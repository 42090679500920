<template>
  <div
    class="circle-chart"
    ref="chartdiv"
  ></div>
</template>

<script>
// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { useTheme, create, Label, color } from '@amcharts/amcharts4/core'
import { PieChart, PieSeries } from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

useTheme(am4themesAnimated)

export default {
  name: 'circle-chart',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 100,
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 50,
    },
    goalData: {
      type: Object,
      default: function () {
        return { count: 0, goal: 0 }
      },
    },
  },
  data () {
    return {
      chart: null,
    }
  },
  watch: {
    goalData (val) {
      if (val > 0) {
        if (!this.chart) {
          this.setupChart()
        }

        this.$nextTick(() => {
          this.chart.data[0].size = this.goalData.count
          this.chart.data[1].size = this.goalData.goal - this.goalData.count
        })
      }
    },
  },
  mounted () {
    this.setupChart()
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    setupChart () {
      const chart = create(this.$refs.chartdiv, PieChart)
      chart.responsive.enabled = true

      // Add label
      chart.innerRadius = this.size
      const label = chart.seriesContainer.createChild(Label)
      let text = this.count + this.text
      if (this.text === '%') {
        text += '\n' + this.$t('dashboard.goals.x_out_of_y', { x: this.goalData.count, y: this.goalData.goal })
      }
      label.text = text
      label.horizontalCenter = 'middle'
      label.verticalCenter = 'middle'
      label.textAlign = 'middle'
      label.fontSize = this.size / 4

      // Add and configure Series
      const pieSeries = chart.series.push(new PieSeries())
      pieSeries.dataFields.value = 'count'
      pieSeries.dataFields.category = 'label'
      pieSeries.colors.list = [
        color('#eef0ef'),
        color(this.color),
      ]

      const slice = pieSeries.slices.template
      // slice.tooltipText = ''
      slice.states.getKey('hover').properties.scale = 1
      slice.states.getKey('active').properties.shiftRadius = 0

      chart.data = [
        { label: this.$t('dashboard.goals.remaining'), count: this.goalData.goal - this.goalData.count },
        { label: this.$t('dashboard.goals.achievement'), count: this.goalData.count },
      ]

      this.chart = chart
      this.$refs.chartdiv.style.height = (this.size * 2) + 'px'
    },
  },
}
</script>

<style scoped>
.circle-chart {
  width: 100%;
  height: 100px;
}
</style>
